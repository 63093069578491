:root {
  --primary-color: #00adee;
  --header-height: 4rem;
  --nav-width: 68px;

  /*===== Colors =====*/
  --first-color: #4723d9;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;

  /*===== Font and typography =====*/
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;

  /*===== z index =====*/
  --z-fixed: 100;
}

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.p-overlay-badge .p-badge {
  position: absolute;
  top: 4px !important;
  right: 3px !important;
}

/* .p-tooltip-arrow {
  border-top-color: var(--primary-color) !important;
}
.p-tooltip-arrow {
  background-color: var(--primary-color) !important;
} */

.blue-tooltip.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #009cd6 !important;
}

.blue-tooltip.p-tooltip .p-tooltip-text {
  background: #009cd6 !important;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--primary-color);
  }
  40% {
    stroke: #009cd6;
  }
  66% {
    stroke: #008abe;
  }
  80%,
  90% {
    stroke: #0079a7;
  }
}

.dx-button-content img {
  border-radius: 50% !important;
}

.nav-list .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
  /* border: 1px solid var(--primary-color); */
  border-radius: 9999px !important;
  /* color: var(--primary-color) !important; */
}

#user-info .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
  border: none !important;
}

.btn-block .dx-button {
  width: 100% !important;
  border-radius: 9999px !important;
}
.react-tel-input .form-control {
  margin: 0;
  padding: 7px 9px 5px 58px !important;
  background: rgba(0, 0, 0, 0);
  color: #333 !important;
  font-size: 1em !important;
  border-radius: 4px !important;
  min-height: 34px !important;
  width: 100% !important;
}

.react-tel-input .form-control:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.form-control:hover {
  border-color: rgba(51, 122, 183, 0.4) !important;
}

.react-tel-input .special-label {
  left: 8px !important;
  color: #999;
}

.react-tel-input:hover .special-label {
  color: var(--primary-color);
}

.dzu-dropzone {
  border-style: dashed;
  border-color: #0079a7;
  overflow: auto;
}

.circular--portrait {
  position: relative;
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 50%;
}
.circular--portrait img {
  width: 100%;
  height: auto;
}

.circular-sm {
  width: 35px !important;
  height: 35px !important;
}

.profile-img .dzu-dropzone {
  border-width: 1px;
}

.profile-img .dzu-inputLabel {
  font-size: 16px;
}

.dzu-inputLabel {
  text-align: center;
  color: var(--primary-color);
  font-size: 18px;
}

.dzu-inputLabelWithFiles {
  margin-bottom: 10px !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.dx-link-delete {
  color: #e53e3e !important;
}

.dxc-title text {
  line-height: 1.25rem !important;
  font-size: 1.125rem !important;
  font-weight: 200 !important;
  font-family: inherit !important;
  text-align: left !important;
}

.blog-main {
  padding: 0;
  margin: 0;
  background-image: url("../images/blog-bg.jpeg");
  width: 100%;
  /* height: 100vh; */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
/* .blog-main::before {
  content: "";
  content: "";
  width: 100%;
  height: 100vh;

  background-image: url("../images/blog-bg.jpeg");
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
} */

/* .dx-calendar {
  min-width: auto !important;
} */

.text-shadow {
  text-shadow: 0px 2px 0px rgb(0 0 0 / 30%);
}

.adjacent-post .arrow-btn {
  transition: width 300ms ease;
  width: 50px;
}

.adjacent-post:hover .arrow-btn {
  width: 60px;
}

.react-multi-carousel-list .arrow-btn {
  transition: width 300ms ease;
  width: 50px;
}

.react-multi-carousel-list:hover .arrow-btn {
  width: 60px;
}

.editor {
  font-family: var(--body-font) !important;
}
