.profile-body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

/*===== HEADER =====*/
.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  /* background-color: var(--white-color); */
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header__toggle {
  color: var(--bluegray-900);
  font-size: 1.5rem;
  cursor: pointer;
}

.header__img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header__img img {
  width: 40px;
}

/*===== NAV =====*/
.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--bluegray-900);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav__logo,
.nav__link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  cursor: pointer;
}

.nav__logo {
  margin-bottom: 2rem;
  color: var(--bluegray-200);
}

.nav__logo-icon {
  /* font-size: 1.25rem; */
  /* color: var(--bluegray-200); */
  /* background-color: var(--primary-color); */
  /* padding: 5px;
  border-radius: 100px; */
  height: 25px;
  width: 25px;
}

.nav__logo-name {
  /* color: var(--bluegray-200); */
  font-weight: 700;
}

.nav__link {
  position: relative;
  color: var(--bluegray-200);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--white-color);
}

.nav__logo:hover {
  color: var(--white-color);
}

.nav__icon {
  font-size: 1.25rem;
}

/*Show navbar movil*/
.show {
  left: 0;
}

/*Add padding body movil*/
.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

/*Active links*/
.active {
  color: var(--primary-color);
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--primary-color);
}

/* ===== MEDIA QUERIES=====*/
@media screen and (min-width: 768px) {
  .profile-body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
  }
  .header {
    height: calc(var(--header-height) + 0.5rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }

  .header__img {
    width: 40px;
    height: 40px;
  }

  .header__img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  /*Show navbar desktop*/
  .show {
    width: calc(var(--nav-width) + 156px);
  }

  /*Add padding body desktop*/
  .body-pd {
    padding-left: calc(var(--nav-width) + 188px);
  }
}
